import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { InputDialog } from 'wui/InputDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { Spinner } from 'wui/Spinner';
import { EmptyState } from 'wui/EmptyState';
import { Popover } from 'wui/Popover';
import { ToggleButton } from 'wui/ToggleButton';
import type { TPAComponentProps } from 'wui/types';
import { Reaction as ReactionIcon } from '@wix/wix-ui-icons-common/on-stage';

import { EmojiPicker } from './EmojiPicker/loadable';

import classes from './ReactionsPicker.scss';

interface IProps extends TPAComponentProps {
  onSelect(code: string): void;
}

export function ReactionsPicker(props: IProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [isOpened, setIsIOpened] = React.useState(false);

  const AddReaction = (
    <ToggleButton
      {...props}
      label="+"
      onChange={openPicker}
      labelPlacement="start"
      className={classes.toggleButton}
      aria-label={t('groups-web.a11y.reactions.more')}
      icon={<ReactionIcon size={16} className={classes.icon} />}
    />
  );

  if (isMobile) {
    return (
      <>
        {AddReaction}
        <InputDialog isOpen={isOpened} onClose={closePicker}>
          <DialogTitle />
          <DialogContent disableSideGutters scrollable={false}>
            <EmojiPicker
              onSelect={props.onSelect}
              fallback={<EmptyState variant="section" title={<Spinner />} />}
            />
          </DialogContent>
        </InputDialog>
      </>
    );
  }

  return (
    <Popover
      empty
      placement="top-start"
      isOpen={isOpened}
      onClose={closePicker}
      content={
        <EmojiPicker
          onSelect={handleSelect}
          fallback={<EmptyState variant="section" title={<Spinner />} />}
        />
      }
    >
      {AddReaction}
    </Popover>
  );

  function handleSelect(code: string) {
    closePicker();

    props.onSelect(code);
  }

  function closePicker() {
    setIsIOpened(false);
  }

  function openPicker() {
    setIsIOpened(true);
  }
}

ReactionsPicker.displayName = 'ReactionsPicker';
